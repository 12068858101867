//
// pages-rtl.scss
//


/* Activity */

.activity-feed {
  .feed-item {
      padding-right: 16px;
      border-right: 2px solid var(--#{$variable-prefix}light);
      border-left: none;

      &:after {
          right: -6px;
      }

  }
}

// Auth

.wrapper .otp-input:not(:last-child) {
  margin-left: 8px;
  margin-right: 0;
}

// timeline

.verti-timeline {
  padding-left: 0;
  padding-right: 100px;

  .event-list {
    border-right: 3px dashed $gray-300;
    border-left: 0;
    padding: 0px 30px 30px 0px;

    &:after {
      left: auto;
      right: -6px;
    }

    .event-date {
      left: auto;
      right: -102px;
    }
  }
}

// Ecommerce

.product-ribbon {
  left: 0px;
  right: auto;
}

.visa-card {
  .visa-pattern {
    left: 0px;
    right: auto;
  }
}

// alert
.custom-alert {
  .close {
    left: 0;
    right: auto;
  }
}


// email

.email-leftbar {
  float: right;
}

.email-rightbar {
  margin-right: 260px;
  margin-left: 0px;
}

.message-list {
  li {
    .col-mail {
      float: right;
    }

    .col-mail-1 {

      .star-toggle,
      .checkbox-wrapper-mail,
      .dot {
        float: right
      }

      .checkbox-wrapper-mail {
        margin: 15px 20px 0 10px;
      }

      .star-toggle {
        margin-right: 5px;
      }

      .title {
        right: 110px;
        left: 0;
      }
    }

    .col-mail-2 {
      right: 320px;
      left: 0;

      .subject {
        right: 0;
        left: 200px;
      }

      .date {
        left: 0;
        right: auto;
        padding-right: 80px;
        padding-left: 0px;
      }

    }
  }

  .checkbox-wrapper-mail {
    label {
      &:before {
        right: 4px;
      }
    }
  }
}

@media (max-width: 767px) {
  .email-leftbar {
    float: none !important;
    width: 100%;
  }

  .email-rightbar {
    margin-right: 0;
  }
}

// authentication

.home-btn {
  position: absolute;
  left: 25px;
  right: auto;
}


// file manager

.categories-list li ul {
  padding-right: 16px;
  padding-left: 0;
}


// Chat

.chat-conversation{
  .conversation-list {
    .ctext-wrap-content{
      margin-right: 16px;
      margin-left: 0;
      border-radius: 8px 0px 8px 8px;
      &:before{
          border-left-color: rgba(91, 115, 232, 0.1);
          right: -10px;
          left: auto;
          border-right-color: transparent;
      }
    }
  }

  .right {
    .conversation-list {
      .ctext-wrap {
        .ctext-wrap-content{
          border-radius: 0px 8px 8px 8px;
          margin-right: 0;
          margin-left: 16px;
          &:before{
            right: auto;
            border-right-color: var(--#{$variable-prefix}light);
            border-left-color: transparent;
            left: -10px;
          }
        }
      }
    }
  }
}

.chat-conversation {
  .conversation-list {
    .dropdown {
      float: left;
    }

    .ctext-wrap {
      border-radius: 8px 8px 0 8px;
    }
  }

  .right {
    float: left;

    .conversation-list {
      .ctext-wrap {
        text-align: left;
        border-radius: 8px 8px 8px 0;
        align-items: flex-start;
      }

      .dropdown {
        float: right;
      }
    }
  }

  .last-chat .conversation-list:before {
    right: auto;
    left: 0;
  }
}

.chat-input {
  padding-left: 120px;
  padding-right: 12px;
}

.chat-input-links {
  right: auto;
  left: 16px;
}

@media (min-width: 576px) {
  .currency-value {
    position: relative;

    &:after {
      right: auto;
      left: -34px;
    }
  }
}


// datatable icon
.datatables {
  thead {
    tr {
      th {
        position: relative;

        &:before {
          content: "\F0143";
          position: absolute;
          left: 0;
          right: auto;
          top: 7px;
          font: normal normal normal 24px/1 "Material Design Icons";
          opacity: 0.3;
        }

        &:after {
          content: "\F0140";
          position: absolute;
          left: 0;
          right: auto;
          top: 14px;
          opacity: 0.3;
          font: normal normal normal 24px/1 "Material Design Icons";
        }
      }

      .asc {
        &:before {
          opacity: 1;
        }
      }

      .desc {
        &:after {
          opacity: 1;
        }
      }
    }
  }
}


